<template>
	<div class="pricing-1" v-if="loaded">
		<div :class="[ $mq.below(960) ? 'container-section-mobile' : 'container-section']">
			<div class="title title-soft-group" style="margin-bottom: 60px;max-width: 100%;" v-html="$t('register.full_packages')"></div>
			<div class="list-prices">
				<div class="price-column" v-for="bundle in bundles" v-if="!bundle.applications.find(el => el.interval == 'year')">
					<div class="price-box" id="plan1">
						<div class="top-part">
							<div class="header">
								<div class="name" >{{ $t('register.full_bundle' , {bundle_name: bundle.name == 'Free' ? 'Pachet gratuit' : bundle.name} ) }}</div>
							</div>
							<div class="price">
								<div class="old-price" v-if="parseInt(bundle.old_price_in_euro) != parseInt(bundle.price_in_euro)">{{ `${bundle.old_price_in_euro}€` }}</div>
								<div class="value">{{ `${bundle.price_in_euro}€` }}</div>
								<span class="period">{{ $t('register.month')}}</span>
							</div>
						</div>
						<div class="list-features" :class="{'border-top': index === 0}" v-for="(application, index)  in bundle.applications">
							<div class="header-applications">
								<div class="title-app">{{ application.application_name }}</div>
								<div class="price"><span class="old" v-if="parseInt(application.old_price_in_euro) != parseInt(application.price_in_euro)">{{ `${application.old_price_in_euro}€` }}</span>{{ `${application.price_in_euro}€` }}</div>
							</div>
							<div class="item-feature" v-for="itemDesc in application.includes">
								<div class="name-feature">{{ itemDesc }}</div>
								<div class="check"><icon-checkbox /></div>
							</div>
						</div>
						<div class="list-features">
							<div class="header-applications">
								<div class="title-app" style="padding-right: 0;">{{ $t('register.33_lessons')}}</div>
							</div>
							<div class="item-feature">
								<div class="name-feature">{{ $t('register.for_all')}}</div>
								<div class="check"><icon-checkbox /></div>
							</div>
						</div>
						
						<div class="action-bundle">
							<button @click="goCart(bundle.type, bundle.package_unique_name)" class="btn-choose-plan">{{ $t('register.buy')}}</button>
							<!-- <button @click="$root.$emit('open_modal', 'demo', {type: 'bundle', option: bundle.type, name: bundle.name})" class="btn-choose-plan">Demo</button> -->
							<span class="info-text">{{ $t('register.prices_without_tva')}}</span>
						</div>
					</div>
				</div>
			</div>

			<template v-for="soft in softs">
				<div class="title title-soft-group mg-top" v-html="$t('register.software_title', {software_name: soft.application_name == 'Proceduri' ? 'Procese si Proceduri' : soft.application_name })">
				</div>
				<div class="section-description">{{ $t('headline_subtitles.'+soft.application_name)}}</div>

				<div class="list-prices">
					<div class="price-column" v-for="packageItem in soft.applications">
						<div class="price-box" id="plan1">
							<div class="top-part">
								<div class="header">
									<div class="name">{{ packageItem.package_name == 'Free' ? 'Pachet gratuit' : packageItem.package_name }}</div>
								</div>
								<div class="price">
									<div class="old-price" v-if="parseInt(packageItem.old_price_in_euro) != parseInt(packageItem.price_in_euro)">{{ `${packageItem.old_price_in_euro}€` }}</div>
									<div class="value">{{ `${packageItem.price_in_euro}€` }}</div>
									<span class="period">{{ $t('register.month')}}</span>
								</div>
							</div>
							<div class="list-features border-top">
								<div class="item-feature" v-for="itemDesc in packageItem.includes">
									<div class="name-feature">{{ itemDesc }}</div>
									<div class="check"><icon-checkbox /></div>
								</div>
							</div>

							<div class="action-bundle">
								<button @click="goCart(soft.type, soft.application_name, packageItem.package_id )" class="btn-choose-plan">{{ $t('register.buy')}}</button>
								<!-- <button @click="goCart(soft.type, soft.application_name, packageItem.package_id )" class="btn-choose-plan">Demo</button> -->
								<span class="info-text">{{ $t('register.prices_without_tva')}}</span>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import IconCheckbox from '../Icons/Checkbox'
	import IconPlus from '../Icons/Plus'
	import IconEquals from '../Icons/Equals'
	import IconArrow from '../../assets/landing/Arrow'

	export default {
		components: {
			IconCheckbox,
			IconPlus,
			IconEquals,
			IconArrow
		},
		data() {
			return {
				activeApplications: [],
				bundles: [],
				softs: [],
				loaded: true
			}
		},
		mounted(){
			this.getBundles()
		},
		methods: {
			getBundles(){
				let paramsQuery = {};
				if(this.$route.query.reducere) {
					paramsQuery.reducere = this.$route.query.reducere
				}
				paramsQuery.from_external = true;

				axios.get('packages', { params: paramsQuery })
				.then(({data}) => {
					this.bundles = data.data.bundles
					this.softs = data.data.softs
				});
			},
			onHoverPlan(number){
				this.activeApplications = number
				setTimeout(() => {
					this.activeButton = number
				}, 500)
			},
			goCart(type, itemName, packageId = false){
				var registerUserData = { 
					year_subscription: false,
					type: type, 
					name: itemName, 
					package: packageId, 
					discount: this.$route.query.reducere ? this.$route.query.reducere : '',
				}

				// this.$cookies.set('optionsRegisterUser', JSON.stringify(registerUserData), '1d', null, ".tbf.ro")
				this.$cookies.set('optionsRegisterUser', JSON.stringify(registerUserData), '1d', null)

				var nextRoute = this.$auth.check() ? 'register-packages' : 'register'
				this.$router.push({name: nextRoute})
			}
		}
	}
</script>